.App {
  font-family: sans-serif;
  text-align: center;
}

.MuiOutlinedIntlTelInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding-top: 1px;
  padding: 8.5px 14px;
}

.MuiOutlinedIntlTelInput-input:focus {
  outline: 0;
}

.MuiOutlinedIntlTelInput-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 4px;
}

.MuiIntlTelField-root .MuiFormLabel-filled {
  max-width: calc(133% - 24px);
}

.MuiOutlinedIntlTelInput-root:hover .MuiOutlinedIntlTelInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.87);
}

.MuiOutlinedIntlTelInput-root.Mui-focused
  .MuiOutlinedIntlTelInput-notchedOutline {
  border-color: #000;
  border-width: 2px;
}

.MuiOutlinedIntlTelInput-notchedOutline {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.MuiOutlinedIntlTelInput-notchedOutline legend {
  display: block;
  width: auto;
  padding: 0;
  height: 11px;
  font-size: 0.75em;
  visibility: hidden;
  max-width: 0.01px;
  -webkit-transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.MuiOutlinedIntlTelInput-notchedOutline legend > span {
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
}

.MuiOutlinedIntlTelInput-root.Mui-filled fieldset legend,
.MuiOutlinedIntlTelInput-root.Mui-focused fieldset legend {
  display: block;
  width: auto;
  padding: 0;
  height: 11px;
  font-size: 0.75em;
  visibility: hidden;
  max-width: 100%;
  -webkit-transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
  transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
}

.MuiOutlinedIntlTelInput-notchedOutline-error {
  border-color: #f44336 !important;
}